html { scrollbar-width: none; }
body::-webkit-scrollbar { display: none; }

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

body {
  margin: 0;
  overflow: -moz-scrollbars-none;
  background-color: #1F1F1F;
  z-index: 1;

  font-family: "IBM-Plex-Mono";
  font-weight: 500;
  font-size: 18px;
  color: #1F1F1F;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  line-height: 140%;
}

a {
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  font-style: italic;
  font-weight: 600;
}

button {
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  text-align: start;
  cursor: pointer;
  outline: inherit;
  text-transform: none;
}

@media (max-width: 970px) {
  body {
    font-size: 15px;
  }
}